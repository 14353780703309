import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './assets/iconfont/iconfont.css';
import './assets/newiconfont/iconfont.css'
import VueCoreVideoPlayer from 'vue-core-video-player'

//适配
import './utils/flexible'
// import * as directive from '@utils/directive'
// import 'amfe-flexible'
//视频
Vue.use(VueCoreVideoPlayer,{
  lang:'zh-CN'
})

import moment from 'moment';

import VueClipboard from 'vue-clipboard2'

//上传全局组件
import elUpload from '@components/el_upload'

import './plugins/ViewUi'
import 'view-design/dist/styles/iview.css';

import './plugins/elementUi'
// import { Monitor } from "../js-sdk-perf.esm.min";

//  new Monitor().init({
//   id:"JwYS1wutEdnIJ7mC",
//   sendSpaPv:true,
// });

import {
  formatSize,
  getSign,
  getImgType,
  editE,
  getShopUserLevelImg,
  md5,
  uuid,
  filterKeyword,
  fileDownload, _throttle,
} from './utils/function'
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
Vue.component('el_Upload',elUpload)

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$getSign = getSign
Vue.prototype.$md5 = md5
Vue.prototype.editE = editE
Vue.prototype.$uuid = uuid
Vue.prototype.$filterKeyword = filterKeyword
Vue.prototype.formatSize = formatSize
Vue.prototype.getImgType = getImgType
Vue.prototype.$editE = editE
Vue.prototype.getShopUserLevelImg = getShopUserLevelImg
Vue.prototype.$fileDownload = fileDownload
Vue.prototype.$_throttle = _throttle
//定义全局过滤器实现日期格式化


Vue.filter("format", function(input, fmtstring) {
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});

Vue.use(VueClipboard)


//修改ico图标
window.SeoUpdate = function(SeoTitle, SeoKeywords, SeoDescription) {
  let _headDom = '',_title = '',_meta = '';

  _headDom = document.getElementsByTagName('head')[0]; //获取head节点
  _title = _headDom.getElementsByTagName("title")[0];  //获取head节点下的title节点
  _meta = _headDom.getElementsByTagName("meta"); //获取head节点下的meta节点，它一般是一个数组

  _title.innerText = SeoTitle;
  for (let index = 0; index < _meta.length; index++) {
    switch (_meta[index].name) {
      case 'keywords':
        _meta[index].content = SeoKeywords;
        break;
      case 'description':
        _meta[index].content = SeoDescription;
        break;

      default:
        break;
    }
  }
}
//自定义全局指令
// for(let key in directive){
//   Vue.directive(key,directive[key])
// }

//浏览器判断
  let goUrl = isMobile();
  if (goUrl === 1) {
    console.log('移动端')
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (!isWeixin) {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid= wx49fa68315f125d05';  // 自己的appid
    }else{
      let url = window.location.hash
      //推广人
      let type = url.substring(url.lastIndexOf('?') + 1,url.lastIndexOf('='))
      //分享连接
      let shareId = url.substr(url.lastIndexOf('=')+1)
      if(type == 's'){
        window.location.href = 'https://s.xntransfer.com/#/?s=' + shareId
      }else{
        window.location.href = 'https://s.xntransfer.com/#/'
      }

    }
  } else {
    //PC地址
    console.log('pcpc')
    // window.location.href = 'https://xntransfer.com/#/'
  }

function isMobile() {
  let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  let goUrl = flag ? 1 : 0;
  return goUrl;
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
