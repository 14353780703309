import Vue from "vue";
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

import {
    Switch,
    Progress,
    Pagination,
    Input,
    Select,
    Option,
    Popover,
    Upload,
    Tree,
    Message,

    Loading,
    Notification,
} from 'element-ui'

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
// Vue.prototype.$loading = Loading
Vue.use(Switch)
Vue.use(Progress)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(Loading)
