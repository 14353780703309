import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from "@/store";
const Home = () => import('../views/Home.vue')

const UCenter = () => import('../views/profile/UCenter.vue')
// 账户设置
const AccountSetting = () => import('../views/profile/account/AccountSetting.vue')
//流量明细
const FlowDetail = () => import('../views/profile/account/FlowDetail.vue')
//实名认证
const RealNameAuth = () => import('../views/profile/account/RealNameAuth.vue')
//消息通知
const MessageNotice = () => import('../views/profile/account/MessageNotice.vue')
//我的钱包
const MyWallet = () => import('../views/profile/account/MyWallet.vue')
//申请提现
const RequestWithdrawal = () => import('../views/profile/account/RequestWithdrawal.vue')
//传输背景
const UploadBackground = () => import('../views/profile/account/UploadBackground.vue')
//免费扩容
const FreeExpansion = () => import('../views/profile/account/FreeExpansion.vue')
//优惠券
const Coupon = () => import('../views/profile/account/Coupon.vue')
//我的文件
const MyFiles = () => import('../views/profile/account/MyFiles.vue')
//回收站
const Recycle = () => import('../views/profile/account/Recycle')
//传输记录
const TransferRecord = () => import('../views/profile/account/TransferRecord.vue')

//咨询
const Advice = () => import('../views/profile/Advice.vue')
const Help = () => import('../views/profile/Help.vue')
//优惠券
const Coupons = () => import('../views/profile/Coupons.vue')

//商城
const Shops = () => import('../views/profile/Shops.vue')
const Shop = () => import('../views/profile/shop/Shop.vue')
//购买记录
const PurchaseRecord = () => import('../views/profile/shop/PurchaseRecord.vue')
//消费记录
const SpendRecord = () => import('../views/profile/shop/SpendRecord.vue')

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:"/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },

  //咨询
  {
    path: '/advice',
    name: 'Advice',
    component: Advice
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  //优惠券
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons
  },
  //商城
  {
    path: '/shops',
    name: 'Shops',
    component: Shops,
    redirect:'/shop',
    children:[


    ]
  },
  {
    path: '/ucenter',
    name: 'UCenter',
    component: UCenter,
    redirect:"/setting",
    children:[
      {
        path:'/setting',
        name:'AccountSetting',
        component: AccountSetting,
      },
      {
        path:'/flowDetail',
        name:'FlowDetail',
        component: FlowDetail,
      },
      {
        path:'/realNameAuth',
        name:'RealNameAuth',
        component: RealNameAuth,
      },
      {
        path:'/messageNotice',
        name:'MessageNotice',
        component: MessageNotice,
      },
      {
        path:'/myWallet',
        name:'MyWallet',
        component: MyWallet,
      },
      {
        path:'/requestWithdrawal',
        name:'RequestWithdrawal',
        component: RequestWithdrawal,
      },
      {
        path:'/uploadBackground',
        name:'UploadBackground',
        component: UploadBackground,
      },
      {
        path:'/freeExpansion',
        name:'FreeExpansion',
        component: FreeExpansion,
      },
      {
        path:'/coupon',
        name:'Coupon',
        component: Coupon,
      },
      {
        path:'/myFiles',
        name:'MyFiles',
        component: MyFiles,
      },
      {
        path: '/recycle',
        name: 'Recycle',
        component:Recycle,
      },
      {
        path:'/transferRecord',
        name:'TransferRecord',
        component: TransferRecord,
      },
      {
        path:'/shop',
        name:'Shop',
        component: Shop,
      },
      {
        path:'/purchaseRecord',
        name:'PurchaseRecord',
        component: PurchaseRecord,
      },
      {
        path:'/spendRecord',
        name:'SpendRecord',
        component: SpendRecord,
      },

    ]
  },
]

const router = new VueRouter({
  mode:'hash',
  routes
})

router.beforeEach((to, from, next)=>{
  let token = localStorage.getItem('token')
  if(token == ''){
    if(to.path == '/advice' ||  to.path == '/home' || to.path == '/help'){
       next()
    }else{
      next('/home')
    }
  }else{
    console.log(Store.state.uploading)
    if(Store.state.uploading){
      Vue.prototype.$Message.error('请等待上传完成后再操作，否则上传将中断')
      return false
    }
    next()
  }

})

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
