import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {uploadFile} from "@assets/api/myFiles";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    comment:0,
    token:localStorage.getItem('token'),
    wxtoken:'',
    webInfo:{},//网站基本信息
    uploadBefore:{},
    downloadUrl:'http://pan.xntransfer.com/',
    share_id:'',
    userInfo:{},//会员信息
    shopInfo:{},//商城信息
    homeInfo:{},//首页信息
    uploadFile:{},//上传文件
    response:'',//随机数 修改文件上传后 跟新页面数据
    flag:true,
    progress:0,//上传进度
    cancel:'',//中断上传
    uploadSize:0,//上传字节
    uploadResponse:'',//上传成功后的数据
    uploadRandom:'',//随机数 监听上传成功后的状态
    t0:new Date(),//
    oldTimestamp:new Date().valueOf(),
    shopType:0,//0 去重置 去扩容
    downLoadInfo:{
        downLoadList:[],
        totalSize:0,
        flowOverTotal:0,
        visitorDownloadMax:0,
        freeDown:'',
    },
    packZipInfo:{},//打包zip
    barrageList:[],//弹幕数组
    uploading:false,//文件上传中状态
    packZipList:[],//下载数组
    showZipOverall:true,//下载弹窗
    closePopup:true,//我的云盘下载弹窗状态
    download_type: 1,
    download_msg: '',
    isPackZip:false,//打包zip
    prevId:'',//文件夹id
    myFilesRandom:'',//我的云盘随机数
    stopUploadFile:'',//中断上传
    surplusMaxSpace:0,//剩余空间
    isUploadFileAll:false,
  },
  mutations: {
    getUserInfo(state,val){
      state.userInfo = val
      // state.userInfo.flow_prop = 100 - state.userInfo.flow_prop
      if(state.userInfo.vip_expiry_time){
        let time = state.userInfo.vip_expiry_time.split(' ')
        state.userInfo.vip_expiry_time = time[0]
      }

    },
    getShopInfo(state,val){
      state.shopInfo = val
      // console.log(state.shopInfo.member_info.vip_expiry_time);
      if(state.shopInfo.member_info.vip_expiry_time){
        let time = state.shopInfo.member_info.vip_expiry_time.split(' ')
        state.shopInfo.member_info.vip_expiry_time = time[0]
      }
    },
    getuploadBefore(state,val){
      state.uploadBefore = val
    },
    getWebInfo(state,val){
      state.webInfo = val
    },
    getHoneInfo(state,val){
      state.homeInfo = val
    },
    getUploadFile(state,val){
      console.log('上传的数据',val)
      state.uploadFile = val
    },
    modifyPanData(state,val){
      state.response = val
    },
      //全局上传
    async submit(state,val){
      //上传前参数
      let uploadBefore =state.uploadFile.uploadBefore
      //目录地址
      let folder_path = state.uploadFile.folder_path
      let folderPath = folder_path == null?'' :folder_path
      //上传开关
      let flag = state.flag
      //  文件总大小
      let countFileSize = 0
      //文件大小
      let uploadSize = state.uploadSize
      //上传进度
      let progress = state.progress
      //直接传状态
      let directlyFileState = true
      //文件名称
      let fileName = ''
      //文件uuid
      let fileuuId = ''
      //单片上传字节
      let shardSize = 0
      // 上一次计算时间
      let lastTime = 0
      // 上一次计算的文件大小
      let lastSize = 0
      let name = ''

        //上传方法
      async function uploadFile(){
          console.log('flag',flag)
          if(!flag) return false
          //上传的文件数组
          let fileList = []
          for(let item in state.uploadFile.fileList){
              if(state.uploadFile.fileList[item].state != 'success'){
                  fileList.push(state.uploadFile.fileList[item])
              }
          }
          for(let item in fileList){
              for(let files in fileList[item].files){
                  let file = fileList[item].files[files].file
                  let folderPath = fileList[item].folderPath ? fileList[item].folderPath :''
                  name  = file.webkitRelativePath?file.webkitRelativePath:file.name
                  countFileSize = fileList[item].size
                  await new Promise(async resolve => {
                  // countFileSize = 0

                  state.flag = false
                  // 直接传
                  if(file.size < 50 *1024 *1024){
                      fileList[item].state = 'uploading'
                      fileList[item].files[files].state = 'uploading'
                      state.progress = 0
                      let callBack
                      if(directlyFileState){
                          fileName = fileList[item].name
                          fileuuId = fileList[item].uuid
                          callBack  =  await directlyFile(file,folderPath)
                          if(callBack.status == 200){
                              fileList[item].state = 'success'
                              fileList[item].files[files].state = 'success'
                              resolve(fileList[item])
                          }
                      }

                  }else{
                      //分块传
                      fileList[item].state = 'uploading'
                      fileList[item].files[files].state = 'uploading'
                      state.progress = 0
                      fileName = fileList[item].name
                      fileuuId = fileList[item].uuid
                      let headers =  await initializeFile(file,folderPath)
                      let success = await handelChunkFile(headers,file,folderPath)
                      //上传成功
                      if(success.status == 201 || success.status == 204){
                          fileList[item].state = 'success'
                          fileList[item].files[files].state = 'success'
                          resolve(fileList[item])
                      }
                  }
                  }).then(res=>{
                      state.uploadResponse = res
                      //文件上传
                      if(res.type == 'file'){
                          // state.uploadResponse = res
                          state.flag = true
                          state.progress = 0
                          uploadSize = 0
                          state.uploadSize = 0
                          uploadFile()
                          state.uploadRandom = Math.random()
                      }else{
                          //文件夹上传
                          let states =  state.uploadResponse.files.every(item=> item.state == "success")
                          if(states){
                              state.flag = true
                              state.progress = 0
                              uploadSize = 0
                              state.uploadSize = 0
                              state.uploadRandom = Math.random()
                              uploadFile()
                          }
                      }
                  })
              }
          }
      }
      await uploadFile()
      //直接传
      async function directlyFile(file,folderPath){
          let name = file.webkitRelativePath?file.webkitRelativePath:file.name
          console.log("直接传",file)
          directlyFileState = false
          let obj =  Vue.prototype.$getSign(
              '',
              uploadBefore.save_key,
              '',
              file.size,
              name,
              uploadBefore.bucket_path,
              uploadBefore.upload_path,
              uploadBefore.uuid_path,
              '',
              folderPath
          )
          return await new Promise( async resolve => {
            await  axios.post(`https://v0.api.upyun.com${'/' + uploadBefore.bucket_path + uploadBefore.upload_path + '/' + uploadBefore.uuid_path + '/' + folderPath}${name}`, file, {
                  onUploadProgress: (progressEvent) => {
                      getProgress(progressEvent)
                  },
                  headers: {
                      'Authorization': obj.sign,
                      "x-date": obj.date,
                  },
                  cancelToken: new axios.CancelToken((cancel) => {
                    state.cancel = cancel
                  })
              }).then(res => {
                uploadSize += (file.size * 1)
                  directlyFileState = true
                  resolve(res)
              })

          })

      }
      //初始化分块任务
      async function initializeFile(file,folderPath){
          console.log('初始化分块任务',folderPath)
          let name = file.webkitRelativePath?file.webkitRelativePath:file.name
          let obj =  Vue.prototype.$getSign(
              '',
              uploadBefore.save_key,
              '',
              file.size,
              name,
              uploadBefore.bucket_path,
              uploadBefore.upload_path,
              uploadBefore.uuid_path,
              '',
              folderPath
          )
          return await new Promise(  resolve => {
              axios.post(`https://v0.api.upyun.com${'/' + uploadBefore.bucket_path + uploadBefore.upload_path + '/' + uploadBefore.uuid_path + '/' + folderPath}${name}`, {}, {
                  headers: {
                      'Authorization':obj.sign,
                      "x-date":obj.date,
                      'X-Upyun-Multi-Stage':'initiate',
                      'X-Upyun-Multi-Length':file.size,
                      // 'X-Upyun-Multi-Part-Size': 10485760,
                      'X-Upyun-Multi-Part-Size': 10 * 1024 * 1024
                  },

              }).then(res => {
                  let headers = res.headers
                  shardSize = headers['x-upyun-next-part-size']
                  resolve(headers)
              })
          })


      }
      //上传分块
      async function handelChunkFile(data,file,folderPath){
          let headers;
          let start;
          let total;
          let end;
          let filedata;//数据
          let item;//下标
          let name = file.webkitRelativePath?file.webkitRelativePath:file.name
          console.log('上传分块',folderPath)
          return await new Promise(async resolve => {
              //合并分块
              async function endupload(data, file) {
                  let obj = Vue.prototype.$getSign(
                      '',
                      uploadBefore.save_key,
                      '',
                      file.size,
                      name,
                      uploadBefore.bucket_path,
                      uploadBefore.upload_path,
                      uploadBefore.uuid_path,
                      '',
                      folderPath
                  )
                  axios.post(`https://v0.api.upyun.com${'/' + uploadBefore.bucket_path + uploadBefore.upload_path + '/' + uploadBefore.uuid_path + '/' + folderPath}${name}`, {}, {
                      headers: {
                          'Authorization': obj.sign,
                          "x-date": obj.date,
                          'X-Upyun-Multi-Stage': 'complete',
                          'X-Upyun-Multi-Uuid': data['x-upyun-multi-uuid'],
                          'X-Upyun-Part-Id': data['x-upyun-next-part-id'],
                          'X-Upyun-Multi-Part-Size': data['x-upyun-next-part-size'],
                      },

                  }).then(res => {
                      headers = res.headers
                      uploadSize += total
                      // uploadSize = 0
                      resolve(res)
                  }).catch(() => {

                  })
              }

              //上传分块
              async function upload(headers, file,folderPath) {
                  item = headers['x-upyun-next-part-id']
                  //处理最后一片 切片
                  if ((headers['x-upyun-remain-size'] * 1) < (shardSize) * 1) {
                      start = item * (shardSize * 1);
                      end = file.size;
                      filedata = file.slice(start, end)
                  } else {
                      start = item * (shardSize * 1);
                      end = (start * 1) + (shardSize * 1);
                      filedata = file.slice(start, end)
                  }
                  let obj = Vue.prototype.$getSign(
                      '',
                      uploadBefore.save_key,
                      '',
                      file.size,
                      name,
                      uploadBefore.bucket_path,
                      uploadBefore.upload_path,
                      uploadBefore.uuid_path,
                      '',
                      folderPath
                  )
                  axios.post(`https://v0.api.upyun.com${'/' + uploadBefore.bucket_path + uploadBefore.upload_path + '/' + uploadBefore.uuid_path + '/' + folderPath}${name}`, filedata, {
                      onUploadProgress: (progressEvent) => {
                          getProgress(progressEvent)
                          if(progressEvent.loaded == progressEvent.total){
                              total = progressEvent.total
                          }
                      },
                      headers: {
                          'Authorization': obj.sign,
                          "x-date": obj.date,
                          'X-Upyun-Multi-Stage': 'upload',
                          'X-Upyun-Multi-Uuid': headers['x-upyun-multi-uuid'],
                          'X-Upyun-Part-Id': headers['x-upyun-next-part-id'],
                          'X-Upyun-Multi-Part-Size': headers['x-upyun-next-part-size'],
                      },
                      cancelToken: new axios.CancelToken((cancel)=>{
                          state.cancel = cancel
                      })
                  }).then(res => {
                      headers = res.headers
                      if (headers['x-upyun-next-part-id'] == -1 && headers['x-upyun-next-part-size'] == -1) {
                          endupload(res.headers, file,folderPath)
                      } else {
                          uploadSize +=  (shardSize * 1)
                          // console.log('headers', headers, 'item', item)
                          upload(res.headers, file,folderPath)
                      }
                  }).catch(async () => {
                      // await upload(headers,file)
                  })
              }
              await upload(data, file,folderPath)
          })


      }
      //计算进度
      function getProgress(progressEvent){
          let loaded = progressEvent.loaded
          var nowTime = new Date().getTime()
          var intervalTime = (nowTime - lastTime)  // 时间单位为毫秒，需转化为秒
          var intervalSize = progressEvent.loaded - lastSize
          /*重新赋值以便于下次计算*/
          lastTime = nowTime
          lastSize = progressEvent.loaded
          /*计算速度*/
          var speed = intervalSize /intervalTime
          var bSpeed = speed // 保存以b/s为单位的速度值，方便计算剩余时间
          var units = '' // 单位名称
          if(speed > 1024){
              speed = (speed / 1024).toFixed(2)
              units = 'M/s'
          }else{
              units = 'kb/s'
              speed = speed < 0 ? 1  : speed.toFixed(2)
          }
          // console.log('speed',speed + units)
          /*计算剩余时间*/
          var leftTime = ((countFileSize  - progressEvent.loaded) / bSpeed)
          /*计算进度*/
          var progress = (uploadSize+ progressEvent.loaded) / countFileSize * 100
          progress = progress >= 100 ? 100:progress;
          speed = speed >0 ?speed:0
          // console.log('当前进度：' + progress.toFixed(1) + '%    当前速度：' + speed.toFixed(1) + units + '   预计剩余时间：' + leftTime.toFixed(1) + '秒')
          for(let item in state.uploadFile.fileList ){
              if(fileName ==  state.uploadFile.fileList[item].name &&
                  fileuuId == state.uploadFile.fileList[item].uuid){
                  state.uploadFile.fileList[item].percentage = progress.toFixed(1)
                  state.uploadFile.fileList[item].speed = speed + units
              }
          }
      }
    },
      //中断上传
    stopUploadFile(state,val){
        let fileList = state.uploadFile.fileList
        fileList.splice(val.index,1)
        state.cancel('')
        state.uploadSize = 0
        state.flag = true
        state.stopUploadFile = Math.random()
        this.commit('submit')
    },
      //全局下载
    handelDownLoadData(state,val){
        state.downLoadInfo.downLoadList = val.downLoadList
        state.downLoadInfo.totalSize = val.totalSize
        state.downLoadInfo.flowOverTotal = val.flowOverTotal
        state.downLoadInfo.visitorDownloadMax = val.visitorDownloadMax
        state.downLoadInfo.freeDown = val.freeDown
        state.downLoadInfo.type = val.type
        state.downLoadInfo.time = val.time
        state.downLoadInfo.flowType = val.flowType
        state.downLoadInfo.shareId = val.shareId
        state.downLoadInfo.id = val.id
    },
     //全局打包
    handelPackZip(state,val){
        state.packZipInfo = val
        // console.log('packZipInfo',state.packZipInfo)
        // console.log('packZipInfo',val)
      },


  },
  actions: {

  },
  modules: {

  },
  getters:{

  }
})
