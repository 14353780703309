<template>
  <div id="app">
    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>

<script>

export default {


}
</script>

<style sc>
canvas{
    z-index: 2;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.el-notification__title{
  font-size: 14px !important;
}

#app{
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 100%;
}
body{
  font-size:100%
}
*{
  font-size: 100%;
}
</style>
