import axios from "axios";
import {Store} from "vuex";
import qs from 'qs'
import {baseUrl} from "../../config";
import store from "@/store";
import $router from "@/router";
const request = axios.create({
    baseURL:baseUrl.url,
    timeout:30000000
})
request.interceptors.request.use((config)=>{
    let token  = store.state.token || localStorage.getItem('token')
    if(token != ''){
        config.headers.token = token
    }
    return config
},error => {
    return Promise.reject(error)
})

request.interceptors.response.use((response)=>{
    switch (response.data.code){
        case -1:
            store.state.token = ''
            localStorage.setItem('token','')
            // $router.push({
            //     path:'/home'
            // })
            break
    }
    return response.data
},error => {
    return Promise.reject(error)
})

export  default  request
