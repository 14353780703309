import request from "@utils/request";

//创建文件夹
export const createFolder = (params)=>{
    return request({
        url:'index/member/createFolder',
        method:'post',
        params
    })
}
//文件重命名
export const rename =(params)=>{
    return request({
        url:'index/member/rename',
        method:'post',
        params
    })
}
//打包成zip文件
export const compressPackage =(data)=>{
    return request({
        url:'index/member/compressPackage',
        method:'post',
        data
    })
}
//复制到
export const copyFiles = (data)=>{
    return request({
        url:'/index/member/copyFiles',
        method:'post',
        data
    })
}
//移动到
export const moveFiles = (data)=>{
    return request({
        url:'/index/member/moveFiles',
        method:'post',
        data
    })
}
//删除文件
export const deleteFiles = (data)=>{
    return request({
        url:'index/member/deleteFiles',
        method:'post',
        data
    })
}
//销毁文件
export const smashFiles = (data)=>{
    return request({
        url:'index/member/smashFiles',
        method:'post',
        data
    })
}
//回收站内销毁文件
export const smashRecycleFile = (data)=>{
    return request({
        url:'index/member/smashRecycleFile',
        method:'post',
        data
    })
}
//获取回收站列表
export const getRecycleList = (params) =>{
    return request({
        url:'index/member/getRecycleList',
        method:'post',
        params
    })
}
//还原回收站
export const reductionRecycle = (data) =>{
    return request({
        url:'index/member/reductionRecycle',
        method:'post',
        data
    })
}
//清空回收站
export const emptyRecycle =()=>{
    return request({
        url:'index/member/emptyRecycle',
        method:'post',
    })
}
//我的云盘搜索
export const searchAll =(params)=>{
    return request({
        url:'index/index/searchAll',
        method:'post',
        params
    })
}
//获取全部文件夹
export const getPanFolderList = ()=>{
    return request({
        url:'index/member/getPanFolderList',
        method:'get',
    })
}
//上传文件到我的云盘
export const uploadFile = (data)=>{
    return request({
        url:'index/member/uploadFile',
        method:'post',
        data
    })
}
//网盘内文件下载
export const diskDownload = (data) =>{
    return request({
        url:'index/member/diskDownload',
        method:'post',
        data
    })
}
//用户查找云盘内的文件
export const searchMemberFiles = (params)=>{
    return request({
        url:'index/member/searchMemberFiles',
        method:'post',
        params
    })
}
//云盘内预览文件
export const panPreviewFile = (params) =>{
    return request({
        url:'index/member/previewFile',
        method:'post',
        params,

    })
}
// 根据文件id跳转目录并返回目录数据
export const jumpFolder = (params) =>{
    return request({
        url:'index/member/jumpFolder',
        method:'post',
        params
    })
}
