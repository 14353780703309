import png from '@assets/img/type/icon_png.png'
import apk from '@assets/img/type/icon_apk.png'
import bmp from '@assets/img/type/icon_bmp.png'
import dmg from '@assets/img/type/icon_dmg.png'
import dwg from '@assets/img/type/icon_dwg.png'
import exe from '@assets/img/type/icon_exe.png'
import flv from '@assets/img/type/icon_flv.png'
import folder from '@assets/img/type/icon_folder.png'
import htm from '@assets/img/type/icon_htm.png'
import html from '@assets/img/type/icon_html.png'
import iso from '@assets/img/type/icon_iso.png'
import ipa from '@assets/img/type/icon_ipa.png'
import jpg from '@assets/img/type/icon_jpg.png'
import mp4 from '@assets/img/type/icon_mp4.png'
import mov from '@assets/img/type/icon_mov.png'
import mp3 from '@assets/img/type/icon_mp3.png'
import pdf from '@assets/img/type/icon_pdf.png'
import ppt from '@assets/img/type/icon_ppt.png'
import pptx from '@assets/img/type/icon_pptx.png'
import psd from '@assets/img/type/icon_psd.png'
import rar from '@assets/img/type/icon_rar.png'
import txt from '@assets/img/type/icon_txt.png'
import tiff from '@assets/img/type/icon_tiff.png'
import wmv from '@assets/img/type/icon_wmv.png'
import doc from '@assets/img/type/icon_doc.png'
import docx from '@assets/img/type/icon_docx.png'
import xlsx from '@assets/img/type/icon_xlsx.png'
import zip from '@assets/img/type/icon_zip.png'
import gif from '@assets/img/type/icon_gif.png'
import rmvb from '@assets/img/type/icon_rmvb.png'
import bak from '@assets/img/type/icon_bak.png'
import none from '@assets/img/type/icon_none.png'
import level1 from '@assets/img/profile/icon-white.png'
import level2 from '@assets/img/profile/icon-blue.png'
import level3 from '@assets/img/profile/icon-black.png'
//会员到期背景图
import expire from '@assets/img/profile/icon-expired.png'


//测试
// export const baseUrl = {
//     url:'https://fzdnz.craftsman-web.com.cn'   //接口地址
// }
// //又拍云 配置文件
// export  const uploadYpy = {
//     key:'yulixinxi',//账号
//     secret:'bIlxOaQbZBJJnB8jwtASmNDQzhw9Y6ah',//密码
//     method:'POST',//上传方式 默认为POST
//     save_key:'/member/{filename}{.suffix}',//上传又拍云路径 默认
//     bucketname:'yunpan999',//服务名称
//     uri:'/yunpan999',//uri
// }

export const baseUrl = {
    url:'https://h.xntransfer.com'   //接口地址
}
//又拍云 配置文件
export  const uploadYpy = {
    key:'olkjzy',//账号
    secret:'snOHrdF64BiDpgnUebIQaiCFnbwfLISz',//密码
    method:'POST',//上传方式 默认为POST
    save_key:'/member/{filename}{.suffix}',//上传又拍云路径 默认
    bucketname:'xn-down',//服务名称
    uri:'/xn-down',//uri
}

//上传文件类型图片
export  const icon_type = {
        'zip':zip,
        'xlsx':xlsx,
        'rmvb':rmvb,
        'bak':bak,
        'docx':docx,
        'doc':doc,
        'wmv':wmv,
        'dwg':dwg,
        'txt':txt,
        'gif':gif,
        'tiff':tiff,
        'rar':rar,
        'psd':psd,
        'pptx':pptx,
        'ppt':ppt,
        'png':png,
        'apk':apk,
        'bmp':bmp,
        'dmg':dmg,
        'exe':exe,
        'flv':flv,
        'folder':folder,
        'htm':htm,
        'html':html,
        'iso':iso,
        'ipa':ipa,
        'jpg':jpg,
        'jpeg':jpg,
        'mp4':mp4,
        'mov':mov,
        'mp3':mp3,
        'mpeg':mp3,
        'mpeg-4':mp3,
        'mldl':mp3,
        'pdf':pdf,
        'none':none,
}

export const viplevelImg ={
        0:'',
        1:level1,
        2:level2,
        3:level3,
        'expire':expire,
}
