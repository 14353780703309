import crypto from "crypto";
import { uploadYpy ,icon_type,viplevelImg} from '../../config'
import SparkMD5 from "spark-md5";
import axios from "axios";
/**
 * 格式化文件大小
 *
 * @param {Object} value 文件大小(字节)
 */
export function formatSize(value) {
  if (null == value || value == '') {
    return "0";
  }
  let unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  let val = value * 1000
  let srcsize = parseFloat(val);
  index = Math.floor(Math.log(srcsize) / Math.log(1000));
  let size = srcsize / Math.pow(1000, index);
  size = size.toFixed(2); //保留的小数位数
  return size + unitArr[index];
}
let index = 0
//获取文件后缀图片
export function getImgType(type){
  let data = type? type.toLowerCase() : type
  if(icon_type[data] == undefined){
    return  icon_type['none']
  }else{
    return  icon_type[data]
  }
}
export function getShopUserLevelImg(data,time){
  if(!data) return  false
  if(data.vip_status == 1){
    if( time >= data.vip_expiry_time){
      return viplevelImg['expire']
    }else{
      return viplevelImg[data.vip_level]
    }
  }else{
    return viplevelImg[0]
  }
}

// 节流
export function _throttle(fn, wait = 200) {
  let last, timer, now;
  return function () {
    now = Date.now();
    if (last && now - last < wait) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.call(this, ...arguments);
      }, wait);
    } else {
      last = now;
      fn.call(this, ...arguments);
    }
  };
}
//解析科学计数
export function editE (num) {
  let math = num.toLocaleString()

  return math.replace(/[,]/g, '');
}
//上传又拍云
export function getSign(method1 = null,save_key1 = null,md5,size,name,bucket_path,uploadUri,uuid_path,time_path,folder_path){
  const crypto = require('crypto');
  const date = new Date().toUTCString();
  const key = uploadYpy.key;
  const secret = uploadYpy.secret;
  const method = method1?method1:uploadYpy.method;
  const save_key=save_key1?save_key1:uploadYpy.save_key;
  const bucketname = uploadYpy.bucketname;
  // const uri1 = uploadYpy.uri;
  const folderPath = folder_path == null?'': folder_path
  const timePath = time_path == ''?'/': '/'+ time_path + '/'
  const uri = '/' + bucketname   + uploadUri + '/'+uuid_path + timePath +  folderPath;
  function sign( method, uri ,date) {
    // console.log('url',url)
    // console.log('uri',encodeURI(uri))
    // console.log('uri',encodeURIComponent(uri))
    const elems = [];
    [method,encodeURI(uri) ,date].forEach(item => {
      if (item != null) {
        elems.push(item)
      }
    })
    let value = elems.join('&');
    // console.log('value',value)
    let auth = hmacsha1(MD5(secret), value);
    // console.log('auth',auth)

    return `UPYUN ${key}:${auth}`;
  }
  function MD5(value) {
    return crypto.createHash('md5').update(value).digest('hex');
  }
  function hmacsha1(secret, value) {
    return crypto.createHmac('sha1', secret).update(value, 'utf8').digest().toString('base64');
  }
  return {
    sign:sign( method, uri + name,date),
    date:date
  }
}
//生成uuid 唯一标识
export function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random()*16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}
//计算md5
export function md5(file,chunkSize){
  let md5 = ''
  return new Promise((resolve, reject) => {
    let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer(); //追加数组缓冲区。
    let fileReader = new FileReader(); //读取文件
    fileReader.onload =  (e)=>{
      spark.append(e.target.result)
      currentChunk++
      md5 = Math.floor((currentChunk / chunks) * 100);
      // _this.container.file.MD5Progress = _this.md5Obj.percent
      if (currentChunk < chunks) {
        loadNext();
      } else {
        md5 = spark.end(); //完成md5的计算，返回十六进制结果。
        resolve(md5);
      }
    }
    function loadNext() {
      let start = currentChunk * chunkSize
      let end = start + chunkSize >= file.size ? file.size : start + chunkSize
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }
    loadNext()
  })
}
//过滤敏感字
export function filterKeyword(str,list){
  let reg = ''
  let flag = false
  for(let item in list){
     reg = new RegExp( list[item]  ,'ig')
    if(reg.test(str)){
      flag = true
      break
    }
  }
  return flag
}
//单文件下载
export function fileDownload(name,downLoadUrl,type){
  // window.location.href = downLoadUrl
  if(type == 1){
    let path = downLoadUrl.indexOf('.cn') != -1 ?downLoadUrl.indexOf('.cn') + 4:downLoadUrl.indexOf('.com') + 5
    let route = downLoadUrl.slice(path)
    var image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png')
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = name || '迅鸟快传' // one是默认的名称
      a.href = url
      a.dispatchEvent(event)
    }
    image.src =  downLoadUrl
    console.log('img11111111111111',image.src,'downLoadUrl',downLoadUrl)
  }else{
    let type = name.slice(name.lastIndexOf('.') + 1)
    console.log('else',type)
    if(type == 'png' || type == 'jpg' || type == 'jpeg' || type == 'art'  || type == 'txt' || type == 'pdf' || type == 'mp3' || type == 'gif'|| type == 'mp4'){
      axios.get(downLoadUrl,{ responseType: 'blob' }).then(res=>{
        console.log('res',res)
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([res.data],{type: res.data.type}))
        link.target = '_blank'
        let filename = res.headers['content-disposition']
        link.download = decodeURI(name)  // 下载的文件名称
        document.body.appendChild(link)  // 添加创建的 a 标签 dom节点
        link.click()  // 下载
        document.body.removeChild(link)  // 移除节点
      })
    }else{
        var anchor = document.createElement('a');
        anchor.href = downLoadUrl;
        anchor.download = downLoadUrl;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }
  }



}