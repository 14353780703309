<template>
  <div>
    <el-upload
        class="upload-demo"
        :http-request="upload_ap"
        action="*"
        :multiple="true"
        v-loading.fullscreen.lock="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :show-file-list="false"
        :file-list="fileList"
        ref="uploadFile"
    >
        <slot name="name"></slot>
    </el-upload>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";
export default {
  name: "el_Upload",
  data(){
    return{
      fileList:[],
      index:0,
      loading:false,
      size:0,
      sizeList:[],
    }
  },
  props:{
    /**
     * type 1 是单/多文件上传 2是文件夹上传
     */
    type:{
      type:Number,
      default:1,
    },
    random:{
    }

  },
  watch:{
    random(){
      this.fileList = []
      this.sizeList = []
    },
    loading(newVal){

      if(newVal){
        this.$store.commit('count')
      }
      console.log('index',this.index)
    },
  },
  mounted() {
    if(this.type == 1){
      this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = false;
    }else{
      this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = true;
    }
  },
  methods:{
    upload_ap(file){
      let md5 = ''
      let that = this
      this.loading = true

      let obj = {
        name:'',
        files:[],
        size:0,
        md5:'',
        suffix:'',
        file:{},
      }
     let state = false

     if(file.file.size == 0){
       this.$message.error('错了哦，这是一条错误消息');
       that.loading = false
       return false
     }


      var  fileReader=  new FileReader()
      var Spark=  new SparkMD5.ArrayBuffer()
      fileReader.readAsArrayBuffer(file.file)
      fileReader.onload =  function(e){
        Spark.append(e.target.result)
        md5 = Spark.end()
        // console.log('md5',md5)
        if(that.type == 1){
          obj.name = file.file.name
          obj.size = file.file.size
          obj.file = file.file
          // obj.sizeArr.push(file.file.size)
          that.sizeList.push(file.file.size)
          obj.suffix = file.file.name.substring(file.file.name.lastIndexOf('.') + 1)
          obj.sizeList = that.sizeList
          // obj.md5 = md5
          obj.files.push({
                file:file.file,
                // md5,
                size: file.file.size
              })
          state = true
          that.loading = false
        }
        if(that.type == 2){
          obj.name = file.file.webkitRelativePath.substring(0,file.file.webkitRelativePath.indexOf('/'))
          // size += file.file.size
          obj.suffix = 'folder'
          obj.file = file.file
          // obj.md5 = md5
          that.sizeList.push(file.file.size)
          that.fileList.push({
                file:file.file,
                // md5,
                size: file.file.size
              })
          obj.files = that.fileList
          obj.sizeList = that.sizeList
          state = true
          that.loading = false
        }
        that.$emit('upLoadApy',obj,state,md5)
      }
    },


  }
}
</script>

